import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterWrapper from "./Routes";
import { Fragment } from "react";


function App() {
  
  return (
    <Fragment>
        {/* ROUTER WRAPPER COMPONENT */}
        <RouterWrapper />

        {/* MODAL COMPONENT */}

        {/* GLOBAL TOASTER COMPONENT */}
        <ToastContainer
        // newestOnTop={true}
        // autoClose={2500}
        // icon={true}
        // position="top-right"
        // hideProgressBar={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        // theme="colored"
        />
    </Fragment>
  );
}

export default App;
