import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "../util";

const type = "RunningGames";
export const runningGamesApi = createApi({
  reducerPath: "runningGamesApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/runningGames`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    fetchRunningGameData: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
  }),
});

export const { useFetchRunningGameDataQuery } = runningGamesApi;
