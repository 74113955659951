import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess from "../utils/constants/validation/response";

const type = "Tables";
export const tablesApi = createApi({
  reducerPath: "tablesApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/table`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getTables: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    createTables: builder.mutation({
      query: (body) => {
        return {
          url: "/create",
          method: "POST",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    updateTables: builder.mutation({
      query: (body) => {
        return {
          url: `/update/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    deleteTables: builder.mutation({
      query: (id) => ({
        url: `/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {
  useCreateTablesMutation,
  useGetTablesQuery,
  useUpdateTablesMutation,
  useDeleteTablesMutation,
} = tablesApi;
