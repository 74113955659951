import { showErrorToast, showSuccessToast } from "../toast";

const isSuccess = (response, msg) => {
  const message = msg || response?.message || "Successfull";
  if (
    isStatusInclude(response?.status || response?.data?.status) &&
    !response?.error
  ) {
    showSuccessToast(message);
    return response;
  }
};

export const isStatusInclude = (status) =>
  [200, 201, 202, "success", "Success"].includes(status);

export const isStatusIncludeError = (status) =>
  [400, 404, 401, 402, 500, "fail", "Fail"].includes(status);

export const isError = (response, msg) => {
  const message =
    msg ||
    response?.message ||
    response?.data?.message ||
    "Something Went Wrong";
  if (
    isStatusIncludeError(response?.status || response?.data?.status) ||
    response?.error
  ) {
    showErrorToast(message);
  } else {
    throw new Error(message); // Throw an error for failure
  }
};

export default isSuccess;
