import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

const type = "AdminHistory";
export const adminHistoryApi = createApi({
  reducerPath: "adminHistoryApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/adminHistory`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getAdminAddBalance: builder.query({
      query: () => {
        return {
          url: "/add",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getAdminWithdraw: builder.query({
      query: () => {
        return {
          url: "/withdraw",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getAdminTransaction: builder.query({
      query: () => {
        return {
          url: "/transaction",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
  }),
});

export const {
  useGetAdminAddBalanceQuery,
  useGetAdminWithdrawQuery,
  useGetAdminTransactionQuery,
} = adminHistoryApi;
