// exerciseSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabValue: 0,
};

const tabValueSlice = createSlice({
  name: "tabValue",
  initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
  },
});

export const { setTabValue } = tabValueSlice.actions;
export default tabValueSlice.reducer;
