import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import { prepareFormData } from "../utils/constants/api/formdata";
import isSuccess from "../utils/constants/validation/response";

const type = "Banner";
export const bannerApi = createApi({
  reducerPath: "bannerApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/banner`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    createBanner: builder.mutation({
      query: (body) => {
        console.log("Request Body:", body); // Log the request body
        let formData = prepareFormData(body);
        return {
          url: "/create",
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    updateBanner: builder.mutation({
      query: (body) => {
        console.log("Request Body:", body); // Log the request body
        const { id, ...data } = body;
        let formData = prepareFormData(data);
        return {
          url: `/update/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {
  useCreateBannerMutation,
  useGetBannerQuery,
  useUpdateBannerMutation,
  useDeleteBannerMutation,
} = bannerApi;
