import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess from "../utils/constants/validation/response";

const type = "Shop"
export const shopApi = createApi({
    reducerPath: "shopApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/shop`,
        prepareHeaders,
    }),
    tagTypes: [type],
    endpoints: (builder) => ({
        getShop: builder.query({
            query: () => {
                return {
                    url: "/get",
                    method: "GET",
                };
            },
            providesTags: [type],
        }),
        createShop: builder.mutation({
            query: (body) => ({
                url: "/create",
                method: "POST",
                body,
            }),
            invalidatesTags: [type],
            transformResponse: (response, meta, arg) => isSuccess(response),
            // invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateShop: builder.mutation({
            query: (body) => {
            return {
                url: `/update/${body?.id}`,
                method: "PUT",
                body,
            }},
            transformResponse: (response, meta, arg) => isSuccess(response),
            invalidatesTags: [type],
        }),
        deleteShop: builder.mutation({
            query: (id) => ({
                url: `/delete/${id}`,
                method: "DELETE",
            }),
            transformResponse: (response, meta, arg) => isSuccess(response),
            invalidatesTags: [type],
        }),
    }),
});

export const { useCreateShopMutation, useGetShopQuery, useUpdateShopMutation, useDeleteShopMutation } = shopApi;