import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

const type = "Dashboard";
export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/dashboard`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
  }),
});

export const { useGetDashboardQuery } =
dashboardApi;
