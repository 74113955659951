import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

const type = "GameAndTransactionHistory";
export const gameAndTransactionHistoryApi = createApi({
  reducerPath: "gameAndTransactionHistoryApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/GameAndTransactionHistory`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getUserGame: builder.query({
      query: (userId) => {
        return {
          url: `/game/${userId}`,
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getUserTransaction: builder.query({
      query: (userId) => {
        return {
          url: `/transaction/${userId}`,
          method: "GET",
        };
      },
      providesTags: [type],
    }),
  }),
});

export const { useGetUserGameQuery ,useGetUserTransactionQuery} = gameAndTransactionHistoryApi;
