import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess from "../utils/constants/validation/response";

const type = "FundWithdraw"
export const fundWithdrawApi = createApi({
    reducerPath: "fundWithdrawApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/withdrawRequest`,
        prepareHeaders,
    }),
    tagTypes: [type],
    endpoints: (builder) => ({
        getFundWithdraw: builder.query({
            query: () => {
                return {
                    url: "/get",
                    method: "GET",
                };
            },
            providesTags: [type],
        }),
        createFundWithdraw: builder.mutation({
            query: (body) => ({
                url: "/create",
                method: "POST",
                body,
            }),
            invalidatesTags: [type],
            transformResponse: (response, meta, arg) => isSuccess(response),
        }),
        updateFundWithdraw: builder.mutation({
            query: (body) => {
            return {
                url: `/update/${body?.id}`,
                method: "PUT",
                body,
            }},
            transformResponse: (response, meta, arg) => isSuccess(response),
            invalidatesTags: [type],
        }),
    }),
});

export const { useCreateFundWithdrawMutation,useGetFundWithdrawQuery,useUpdateFundWithdrawMutation } = fundWithdrawApi;