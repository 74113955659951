import { createApi } from "@reduxjs/toolkit/query/react";

import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor } from "./util";
import isSuccess from "../utils/constants/validation/response";

const tagTypes = "Auth";
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: api.baseURL,
  }),
  tagTypes: [tagTypes],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [tagTypes],
    }),
    forgetPassword: builder.mutation({
      query: (body) => ({
        url: "/sendOtp",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [tagTypes],
    }),
    compareCode: builder.mutation({
      query: (body) => ({
        url: "/otpVerify",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [tagTypes],
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/reset",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),

      invalidatesTags: [tagTypes],
    }),
    refreshToken: builder.mutation({
      query: (body) => ({
        url: "/token",
        method: "POST",
        body,
      }),
      invalidatesTags: [tagTypes],
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useCompareCodeMutation,
  useResetPasswordMutation,
  useRefreshTokenMutation,
} = authApi;
