import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import { prepareFormData } from "../utils/constants/api/formdata";
import isSuccess from "../utils/constants/validation/response";

const type = "Profile";
export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/profile`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getReal: builder.query({
      query: () => {
        return {
          url: "/getReal",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getBot: builder.query({
      query: () => {
        return {
          url: "/getBot",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getBotHistory: builder.query({
      query: (botId) => {
        return {
          url: `/botHistory/${botId}`,
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    addBotBalance: builder.mutation({
      query: (body) => {
        return {
          url: "/addBalance",
          method: "POST",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    rewardUser: builder.mutation({
      query: (body) => {
        return {
          url: "/reward",
          method: "PUT",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    createProfile: builder.mutation({
      query: (body) => {
        console.log("Request Body:", body); // Log the request body
        let formData = prepareFormData(body);
        return {
          url: "/create",
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        console.log("Request Body:", body); // Log the request body
        const { id, ...data } = body;
        let formData = prepareFormData(data);
        return {
          url: `/update/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    deleteProfile: builder.mutation({
      query: (id) => ({
        url: `/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {
  useCreateProfileMutation,
  useGetProfileQuery,
  useGetRealQuery,
  useGetBotQuery,
  useGetBotHistoryQuery,
  useAddBotBalanceMutation,
  useRewardUserMutation,
  useUpdateProfileMutation,
  useDeleteProfileMutation,
} = profileApi;
