import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess from "../utils/constants/validation/response";

const type = "Tournament";
export const tournamentApi = createApi({
  reducerPath: "tournamentApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/tournament`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getTournament: builder.query({
      query: () => {
        return {
          url: "/get",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getTournamentMatchDetails: builder.query({
      query: (tournamentId) => {
        return {
          url: `/matchDetail/${tournamentId}`,
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    createTournament: builder.mutation({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    updateTournament: builder.mutation({
      query: (body) => {
        return {
          url: `/update/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
    deleteTournament: builder.mutation({
      query: (id) => ({
        url: `/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {
  useCreateTournamentMutation,
  useGetTournamentQuery,
  useGetTournamentMatchDetailsQuery,
  useUpdateTournamentMutation,
  useDeleteTournamentMutation,
} = tournamentApi;
