import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess from "../utils/constants/validation/response";

const type = "TournamentWinPrize";
export const tournamentWinPrizeApi = createApi({
  reducerPath: "tournamentWinPrizeApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/tournamentPrize`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    createTournamentPrize: builder.mutation({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) => isSuccess(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {
  useCreateTournamentPrizeMutation,
} = tournamentWinPrizeApi;
