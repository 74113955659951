import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

const type = "GameAndTournamentHistory";
export const gameAndTournamentHistoryApi = createApi({
  reducerPath: "gameAndTournamentHistoryApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/history`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getHistoryAllGame: builder.query({
      query: () => {
        return {
          url: "/allGame",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
    getHistoryTournament: builder.query({
      query: () => {
        return {
          url: "/tournament",
          method: "GET",
        };
      },
      providesTags: [type],
    }),
  }),
});

export const { useGetHistoryAllGameQuery, useGetHistoryTournamentQuery } =
  gameAndTournamentHistoryApi;
