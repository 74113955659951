import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";
import isSuccess, { isError } from "../utils/constants/validation/response";

const type = "userTransaction";
export const userTransactionApi = createApi({
  reducerPath: "userTransactionApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/userTransaction`,
    prepareHeaders,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (body) => {
        return {
          url: "/create",
          method: "POST",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      transformErrorResponse: (response, meta, arg) => isError(response),
      invalidatesTags: [type],
    }),
    createPayment: builder.mutation({
      query: (body) => {
        return {
          url: "/create-payment",
          method: "POST",
          body,
        };
      },
      transformResponse: (response, meta, arg) => isSuccess(response),
      transformErrorResponse: (response, meta, arg) => isError(response),
      invalidatesTags: [type],
    }),
  }),
});

export const {useCreatePaymentMutation,useCreateMutation} = userTransactionApi;
