// api.js
export const api = {
  baseURL: process.env.REACT_APP_BASE_URL || window.location.origin,
};

export const socket = {
  baseURL:
    process.env.REACT_APP_SOCKET_API_BASE_URL ||
    `wss://${window.location.hostname}`,
};
