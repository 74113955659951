export const ROUTES = {
  HOME: "/",
  OTP_VERIFY:"otp-verify",
  AUTH: {
    FORGOT_PASSWORD: "/auth/forgot-password",
    VERIFY_OTP: "/auth/verify-otp",
    RESET_PASSWORD: "/auth/reset-password",
  },
  ADMIN: {
    ACCOUNT: "/admin/account",
  },
  DASHBOARD:{
    HOME:"/dashboard"
  },
  USER: {
    HOME: "/user",
    DETAILS:"/user/details/:userId",
  },

  RUNNING_GAMES:{
    HOME:"/running-games"
  },
  TABLES:{
    HOME:"/tables"
  },
  BOT:{
    HOME:"/bot",
    DETAILS:"/bot/details/:botId"
  },
  TOURNAMENT: {
    HOME: "/tournament",
    MATCHDETAILS:"/tournament/match-details/:tournamentId",
  },
  FUND_WITHDRAW: {
    HOME: "/fund-withdraw",
  },
  SHOP: {
    HOME: "/shop",
  },
  BANNER:{
    HOME: "/banner",
  },
  GAME_HISTORY:{
    HOME: "/game-history",
  },
  ADMIN_HISTORY:{
    HOME: "/admin-history",
  }
};
