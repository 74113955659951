// customTheme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Jost, sans-serif",
  },
  color: {
    primary: "#8448AB",
    primary_33:"#8448AB33",
    primary_50:"#8656A5",
    primary_100: "#965FB9",

    success_dark: "#16C13C",
    success_light: "rgba(22, 193, 60, 0.10)",
    success_50: "#ECFDF5",
    success_100: "#064E3B",

    warning_dark: "#F3C10F",
    warning_light: "rgba(243, 193, 15, 0.10)",
    warning_50: "#F2E14226",
    warning_100: "#CAB709",

    error_dark: "#F00",
    error_light: "rgba(255, 0, 0, 0.10)",
    error_50: "#FEF2F2",
    error_100: "#991B1B",
    error_200:"#F65D05",

    white: "#fff",
    bgMainColor: "#f9f9f9",

    black: "#000",
    black_80:"#00000080",
    black_50:"#191D23",
    black_50_10:"#191D231A",
    black_50_80: "#191D2380",
    black_50_59:"#191D2359",
    black_100: "#D0D5DD",
    black_200:"#344054",
    black_300:"#292B2D",
    black_400:"#090A0A",

    // grey:"#808080",
    grey_50:"#939393",
    grey_100:"#dfdfdf",
    grey_200:"#0000004D",
    grey_300:"#D0D5DD80",
    grey_400:"#ECECEC",
    grey_500:"#D9D9D9",
  },

  //   "& .swal2-confirm":{
  // backgroundColor:"#8448AB",
  //   },
  //   "& .delete-button":{
  //     backgroundColor:"#8448AB",
  //   },

  //mui color palette for custom color
  palette: {
    primary: {
      main: "#8448AB",
    },
  },
});

export default theme;
