import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ROUTES } from "./utils/constants/variables/routes";
import { lazy, Suspense, useEffect } from "react";
import { LinearProgress } from "@mui/material";

const PhoneNumber = lazy(() => import("./pages/AuthPage/sms/phoneNumber"));
const VerifyOtp = lazy(() => import("./pages/AuthPage/sms/otpCode"));
const Login = lazy(() => import("./pages/AuthPage/login"));
const OtpVerify = lazy(() => import("./pages/AuthPage/otpVerify"));
const ChangePassword = lazy(() => import("./pages/AuthPage/changePassword"));
const User = lazy(() => import("./pages/masterAdmin/user"));
const Shop = lazy(() => import("./pages/masterAdmin/shop"));
const FundWithdraw = lazy(() => import("./pages/masterAdmin/fundWithdraw"));
const ForgotPassword = lazy(() => import("./pages/AuthPage/forgotPassword"));
const Organization = lazy(() => import("./pages/masterAdmin/organization"));
const UserDetail = lazy(() => import("./pages/masterAdmin/user/userDetail"));
const Bot = lazy(() => import("./pages/masterAdmin/bot"));
const RunningGames = lazy(() => import("./pages/masterAdmin/runningGame"));
const Dashboard = lazy(() => import("./pages/masterAdmin/dashboard"));
const Banner = lazy(() => import("./pages/masterAdmin/banner"));
const AdminHistory = lazy(() => import("./pages/masterAdmin/adminHistory"));
const GameHistory = lazy(() => import("./pages/masterAdmin/gameHistory"));
const Tables = lazy(() => import("./pages/masterAdmin/tables"));
const TournamentList = lazy(() =>
  import("./pages/masterAdmin/tournament/tournamentList")
);
const MatchDetails = lazy(() =>
  import("./pages/masterAdmin/tournament/matchDetails")
);
const BotDetails = lazy(() => import("./pages/masterAdmin/bot/botDetails"));

const authRoutes = [
  {
    path: ROUTES.HOME,
    element: <Login />,
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: ROUTES.AUTH.VERIFY_OTP,
    element: <OtpVerify />,
  },
  {
    path: ROUTES.AUTH.RESET_PASSWORD,
    element: <ChangePassword />,
  },
];

const masterAdminRoutes = [
  {
    element: <Organization />,
    children: [
      {
        path: ROUTES.DASHBOARD.HOME,
        element: <Dashboard />,
      },
      {
        path: ROUTES.USER.HOME,
        element: <User />,
      },
      {
        path: ROUTES.USER.DETAILS,
        element: <UserDetail />,
      },
      {
        path: ROUTES.BOT.HOME,
        element: <Bot />,
      },
      {
        path: ROUTES.BOT.DETAILS,
        element: <BotDetails />,
      },
      {
        path: ROUTES.RUNNING_GAMES.HOME,
        element: <RunningGames />,
      },
      {
        path: ROUTES.TABLES.HOME,
        element: <Tables />,
      },
      {
        path: ROUTES.TOURNAMENT.HOME,
        element: <TournamentList />,
      },
      {
        path: ROUTES.TOURNAMENT.MATCHDETAILS,
        element: <MatchDetails />,
      },
      {
        path: ROUTES.FUND_WITHDRAW.HOME,
        element: <FundWithdraw />,
      },
      {
        path: ROUTES.SHOP.HOME,
        element: <Shop />,
      },
      {
        path: ROUTES.BANNER.HOME,
        element: <Banner />,
      },
      {
        path: ROUTES.GAME_HISTORY.HOME,
        element: <GameHistory />,
      },
      {
        path: ROUTES.ADMIN_HISTORY.HOME,
        element: <AdminHistory />,
      },
    ],
  },
];

const routeConfig = [
  ...authRoutes,
  ...masterAdminRoutes,
  { path: "*", element: <Navigate to={ROUTES.HOME} replace /> },
];

const Auth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token && refreshToken && pathname === ROUTES.HOME)
      navigate(ROUTES.DASHBOARD.HOME);
  }, [pathname, navigate]);

  return null;
};

const RouterWrapper = () => {
  /**
   *
   * @param {*} routes
   * @returns Routes & Nested Route Component recursively
   */
  function generateRoutes(routes) {
    return routes.map((route, index) => {
      const { path, element, children } = route;
      return (
        <Route key={index} path={path} element={element}>
          {children && children.length > 0 && generateRoutes(children)}
        </Route>
      );
    });
  }

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LinearProgress />}>
          <Auth />
          <Routes>{generateRoutes(routeConfig)}</Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default RouterWrapper;
