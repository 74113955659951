import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import _ from "lodash";
import authSlice from "../redux/authSlice";
import authReducer from "../redux/authSlice";
import { authApi } from "../api/auth";
import { profileApi } from "../api/profile";
import { tablesApi } from "../api/tables";
import { shopApi } from "../api/shop";
import { bannerApi } from "../api/banner";
import { tournamentApi } from "../api/tournament";
import { botSwitchApi } from "../api/botSwitch";
import { gameAndTransactionHistoryApi } from "../api/userDetails";
import { tournamentWinPrizeApi } from "../api/tournamentWinPrizel";
import { fundWithdrawApi } from "../api/fundWithdraw";
import { adminHistoryApi } from "../api/adminHistory";
import { gameAndTournamentHistoryApi } from "../api/gameHistory";
import { dashboardApi } from "../api/dashboard";
import { runningGamesApi } from "../api/webSocket/runningGames";
import { userTransactionApi } from "../api/userTransaction";
import tabValueReducer from "./tabValueSlice";
import tabValueSlice from "./tabValueSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    tabValue: tabValueReducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [tablesApi.reducerPath]: tablesApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [tournamentApi.reducerPath]: tournamentApi.reducer,
    [botSwitchApi.reducerPath]: botSwitchApi.reducer,
    [gameAndTransactionHistoryApi.reducerPath]:
      gameAndTransactionHistoryApi.reducer,
    [tournamentWinPrizeApi.reducerPath]: tournamentWinPrizeApi.reducer,
    [fundWithdrawApi.reducerPath]: fundWithdrawApi.reducer,
    [gameAndTournamentHistoryApi.reducerPath]:
      gameAndTournamentHistoryApi.reducer,
    [adminHistoryApi.reducerPath]: adminHistoryApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [runningGamesApi.reducerPath]: runningGamesApi.reducer,
    [userTransactionApi.reducerPath]: userTransactionApi.reducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(profileApi.middleware)
      .concat(tablesApi.middleware)
      .concat(shopApi.middleware)
      .concat(bannerApi.middleware)
      .concat(tournamentApi.middleware)
      .concat(botSwitchApi.middleware)
      .concat(gameAndTransactionHistoryApi.middleware)
      .concat(tournamentWinPrizeApi.middleware)
      .concat(fundWithdrawApi.middleware)
      .concat(gameAndTournamentHistoryApi.middleware)
      .concat(adminHistoryApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(runningGamesApi.middleware)
      .concat(userTransactionApi.middleware)
      ,
});

setupListeners(store.dispatch);

const createActions = (slice) => _.mapValues(slice.actions, (actionCreator) => (payload) => store.dispatch(actionCreator(payload)));

export const actions = {
    auth: createActions(authSlice),
    tabValue: createActions(tabValueSlice),
};

export default store;
